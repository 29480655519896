<template>
  <div class="myInfo">
    <div class="menu">
      <span>마이페이지</span>
      <img src="@/assets/image/icn-arrow_right.svg" alt="arrow" />
      <span class="active">회원정보 수정</span>
    </div>
    <article class="inner">
      <div class="form">
        <div class="flex">
          <p>
            이름
            <span>*</span>
          </p>
          <input type="text" v-model="username" />
        </div>
        <div class="flex">
          <p>
            이메일
            <span>*</span>
          </p>
          <input type="text" v-model="email" disabled />
          <!-- <input type="text" disabled v-model="email" /> -->
        </div>
        <div class="flex">
          <p>
            휴대전화
            <span>*</span>
          </p>
          <div class="cert">
            <input type="text" v-model="mobileNo" @keyup="removeChar" placeholder="숫자만 입력해주세요." />
            <button class="point" @click="requestCertNumber">인증번호 받기</button>
          </div>
        </div>
        <div class="flex" v-show="isRequestCertNum">
          <p>
            인증번호
            <span>*</span>
          </p>
          <div class="cert">
            <input class="tel" type="tel" v-model="certNumber" @keyup="removeChar" placeholder="인증번호를 입력해주세요." />
            <button class="pointOut" @click="verifyNumber">인증번호 확인</button>
          </div>
        </div>
        <div class="flex">
          <p>
            생년월일
            <span>*</span>
          </p>
          <div class="birth">
            <!-- <input
                type="text"
                v-model="year"
                placeholder="년"
                maxlength="4"
                @keyup="removeChar"
            />-->
            <select class="sel" v-model="birthYear">
              <option disabled selected value>년</option>
              <option v-for="(data, i) in 93" :key="i" :value="year - i">{{ year - i }}</option>
            </select>
            <select class="sel" v-model="month">
              <option disabled selected value>월</option>
              <option v-for="(data, i) in 12" :key="i" :value="i + 1">{{ i + 1 }}</option>
            </select>
            <select class="sel" v-model="day">
              <option disabled selected value>일</option>
              <option v-for="(data, i) in 31" :key="i" :value="i + 1">{{ i + 1 }}</option>
            </select>
          </div>
        </div>
        <div class="flex">
          <p>
            성별
            <span>*</span>
          </p>
          <div class="gender">
            <label class="genderRadio">
              <input type="radio" v-model="gender" value="M" />
              <span>남</span>
            </label>
            <label class="genderRadio">
              <input type="radio" v-model="gender" value="F" />
              <span>여</span>
            </label>
          </div>
        </div>
        <div class="flex">
          <p>
            결혼여부
            <span>*</span>
          </p>
          <div class="gender">
            <label class="genderRadio">
              <input type="radio" v-model="isMarried" value="true" />
              <span>기혼</span>
            </label>
            <label class="genderRadio">
              <input type="radio" v-model="isMarried" value="false" />
              <span>미혼</span>
            </label>
          </div>
        </div>
        <div class="flex">
          <p>
            직업
            <span>*</span>
          </p>
          <select v-model="job">
            <option value disabled>선택</option>
            <option value="EMPLOYEE">직장인</option>
            <option value="SELFEMPLOYED">자영업</option>
            <option value="UNEMPLOYED">무직</option>
          </select>
        </div>
        <div class="flex">
          <p>
            주소
            <span>*</span>
          </p>
          <select v-model="address">
            <option value disabled>선택</option>
            <option :value="data.value" v-for="(data, i) in region" :key="i">{{ data.value }}</option>
          </select>
        </div>
        <button class="point" @click="submit()">수정하기</button>
      </div>
    </article>
  </div>
</template>
<script>
import { format } from "@/mixins/format";
import moment from "moment";
import { updateUser, updateUserPwd, verifyMobile, createCertNumber, fetchProfile } from "@/api/index";
import { mapState } from "vuex";
import region from "@/config/region.json";

export default {
  mixins: [format],
  data() {
    return {
      moment: moment,
      visible: false,
      password: "",
      passwordConfirm: "",
      newPassword: "",
      personDataCollectAgreeFlag: false,
      birthYear: "",
      month: "",
      day: "",
      gender: "",
      certNumber: "",
      isVerify: false,
      year: "",
      region: [],
      address: "",
      isMarried: null,
      job: "",
      isRequestCertNum: false,
    };
  },
  computed: {
    ...mapState(["email"]),
    username: {
      get() {
        return this.$store.state.username;
      },
      set(value) {
        let data = {
          username: value,
        };
        return this.$store.dispatch("SET_USER_INFO", data);
      },
    },
    mobileNo: {
      get() {
        return this.$store.state.mobileNo;
      },
      set(value) {
        let data = {
          mobileNo: value,
        };
        return this.$store.dispatch("SET_USER_INFO", data);
      },
    },
  },
  mounted() {
    this.region = region.region;
    this.getProfile();
  },
  methods: {
    setYear() {
      let today = new Date();
      this.year = today.getFullYear();
    },
    getProfile() {
      this.setYear();
      fetchProfile().then((res) => {
        if (res.data.status == 200) {
          this.password = res.data.data.password;
          this.username = res.data.data.username;
          this.mobileNo = res.data.data.mobileNo;
          this.gender = res.data.data.gender;
          this.birthYear = new Date(res.data.data.birth).getFullYear();
          this.month = new Date(res.data.data.birth).getMonth() + 1;
          this.day = new Date(res.data.data.birth).getDate();
          this.address = res.data.data.address;
          this.job = res.data.data.job;
          this.isMarried = res.data.data.isMarried;
        } else {
          alert(res.data.message);
        }
      });
    },
    requestCertNumber() {
      if (this.mobileNo == "") {
        return alert("휴대폰번호를 입력해 주세요.");
      } else if (this.mobileNumberCheck(this.mobileNo) == false) {
        return alert("휴대폰번호 형식이 맞지 않습니다.");
      }
      let data = {
        mobileNo: this.mobileNo,
      };
      this.isRequestCertNum = true;
      createCertNumber(data).then((res) => {
        if (res.data.status == 200) {
          return alert("모바일을 통해서 인증번호를 확인 후 인증번호를 입력해주세요.");
        } else {
          alert(res.data.message);
        }
      });
    },
    verifyNumber() {
      if (this.certNumber == "") {
        return alert("인증 번호를 입력해 주세요.");
      }
      let data = {
        mobileNo: this.mobileNo,
        certNumber: this.certNumber,
      };
      verifyMobile(data).then((res) => {
        if (res.data.status == 200) {
          this.isVerify = true;
          return alert("정상적으로 인증이 완료 되었습니다.");
        } else if (res.data.status == 401) {
          this.isVerify = false;
          return alert(res.data.message);
        } else if (res.data.status == 407) {
          this.isVerify = false;
          return alert(res.data.message);
        } else if (res.data.status == 409) {
          this.isVerify = false;
          return alert(res.data.message);
        }
      });
    },

    removeChar(event) {
      if (event.keyCode == 8 || event.keyCode == 46 || event.keyCode == 37 || event.keyCode == 39) return;
      else event.target.value = event.target.value.replace(/[^0-9]/g, "");
    },
    handleVisible() {
      this.visible = !this.visible;
    },
    submit() {
      if (this.email == "") {
        return alert("이메일을 입력해 주세요.");
      } else if (this.emailValidate(this.email) == false) {
        return alert("이메일 형식이 맞지 않습니다.아래와 같이 이메일 주소 전체를 입력해주세요. service@ulmani.co.kr");
      } else if (this.username == "") {
        return alert("이름을 입력해 주세요.");
      } else if (this.mobileNo == "") {
        return alert("휴대폰번호를 입력해 주세요.");
        // } else if (this.verify == false) {
        //   return alert("휴대폰 인증을 해주세요.");
      } else if (this.birthYear == "" || this.month == "" || this.day == "") {
        return alert("생년월일을 입력해 주세요.");
      } else if (this.gender == "") {
        return alert("성별을 선택해 주세요.");
      } else if (this.isMarried == null) {
        return alert("결혼 여부를 선택해 주세요.");
      } else if (this.job == "") {
        return alert("직업을 선택해 주세요.");
      } else if (this.address == "") {
        return alert("주소를 선택해 주세요.");
      }
      this.birth = this.birthYear + "-" + this.month + "-" + this.day;

      let data = {
        mobileNo: this.mobileNo,
        username: this.username,
        nickname: this.nickname,
        birth: this.birth,
        gender: this.gender,
        address: this.address,
        isMarried: this.isMarried,
        job: this.job,
      };
      updateUser(data).then((res) => {
        if (res.data.status == 200) {
          alert("회원정보가 수정 되었습니다.");
        } else {
          let message = res.data.message;
          alert(message);
        }
      });
    },
    updatePassword() {
      if (this.password == "") {
        return alert("현재 비밀번호를 입력해주세요.");
      } else if (this.newPassword == "") {
        return alert("신규 비밀번호를 입력해주세요.");
      } else if (this.passwordConfirm != this.newPassword) {
        return alert("비밀번호를 다시 입력해주세요.");
      }

      let data = {
        email: this.email,
        password: this.password,
        newPassword: this.newPassword,
      };
      updateUserPwd(data).then((res) => {
        if (res.data.status == 200) {
          this.visible = true;
          this.password = "";
          this.newPassword = "";
          this.passwordConfirm = "";
          this.visible = false;
          alert("비밀번호가 변경 되었습니다.");
        } else {
          let message = res.data.message;
          alert(message);
        }
      });
    },
  },
};
</script>
